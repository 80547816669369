import React, { useState, useRef, useEffect } from "react";

const Header: React.FC = () => {
  const [flag, setFalg] = useState(false);
  const toggle = () => {
    let toggle: any = document.getElementById("toggle");
    toggle.classList.toggle("toggle");
    setFalg(!flag);
  };

  // const videoRef1 = useRef<HTMLVideoElement>(null);

  // useEffect(() => {
  //   const videoElement1 = videoRef1.current;
  //   if (videoElement1) {
  //     videoElement1.muted = true; // Mute the video to comply with autoplay restrictions
  //     videoElement1.play().catch((error) => {
  //       console.error("Video playback failed:", error);
  //     });
  //   }
  // }, []);
  return (
    <div className="relative">
      <video src="./main.mp4" className="w-full" muted loop autoPlay playsInline preload="auto"></video>
      <div className="absolute top-0 left-0 w-full h-full">
        <div className="absolute left-1/2 top-[1%] h-[6%] md:h-[4%] xl:h-[3%] -translate-x-1/2 px-[1%] w-full flex flex-row items-center justify-between sm:gap-4">
          <a
            href="https://dexscreener.com/"
            target="_blank"
            rel="noreferrer"
            className="h-full"
          >
            <img
              src="./dex.png"
              alt=""
              className="h-full hover:scale-110 transition-all duration-200"
            />
          </a>
          <div className="flex flex-row gap-4 items-center justify-center h-full">
            <a
              href="https://x.com/GrokChronicles"
              target="_blank"
              rel="noreferrer"
              className="h-full"
            >
              <img
                src="./x.png"
                alt=""
                className="h-full hover:scale-110 transition-all duration-200"
              />
            </a>
            <a
              href="https://t.me/grokchronicles"
              target="_blank"
              rel="noreferrer"
              className="h-full"
            >
              <img
                src="./tele.png"
                alt=""
                className="h-full hover:scale-110 transition-all duration-200"
              />
            </a>
          </div>
        </div>
      </div>
      {/* <h1 className="w-full absolute bottom-[1%] text-white text-center text-xs sm:text-lg lg:text-xl">
        Description: A slightly obese purple friend.
      </h1> */}
    </div>
  );
};

export default Header;
